import Vue from 'vue'
import PhotoModal from 'views/modals/PhotoModal'

const PhotoModalComponent = Vue.extend(PhotoModal)

const openPhotoModal = (propsData = {
  visible: true,
  title: 'Выбор фотографии'
}) => {
  return new PhotoModalComponent({
    el: document.createElement('div'),
    propsData
  })
}

export default {
  openPhotoModal,
}
