import { withParams, req } from 'vuelidate/lib/validators/common'
import moment from 'moment'

export function regex (expr) {
  return withParams({type: 'regex', expr}, value => !req(value) || expr.test(value))
}

export function datetime (format) {
  return withParams({type: 'datetime', format}, value => !req(value) || moment(value, format).isValid())
}

export function ext (extensions) {
  const regex = new RegExp(`.(${extensions.join('|')})$`, 'i')

  return withParams({type: 'ext', extensions}, file => !req(file) || regex.test(file.name))
}

export function httpUrl (value = '') {
  return /^(http:\/\/|https:\/\/)/.test(value)
}
