<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">

                <spinner v-show="loading"></spinner>
                <article class="tile is-child box" v-if="!loading && item">

                    <h1 class="title">Агент <span v-if="!isNew && item">#{{ item.id }}</span></h1>
                    <h2 class="subtitle" v-if="!isNew && item">
                        <a :href="referUrl" target="_blank">{{ referUrl }}</a>
                        <span
                            class="clickable"
                            v-clipboard:copy="referUrl"
                            v-clipboard:success="onCopyReferUrl"
                        >(скопировать)</span>
                    </h2>

                    <form class="block" @submit.prevent="save">

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Фио</label>
                            </div>
                            <div class="control">
                                <input
                                  class="input"
                                  type="text"
                                  v-model.trim="item.full_name"
                                  :class="{ 'is-danger': $v.item.full_name.$error }"
                                  @input="$v.item.full_name.$touch()"
                                >
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Дата рождения</label>
                            </div>
                            <div class="control">
                                <input-mask
                                    mask="99.99.9999"
                                    class="input"
                                    type="text"
                                    v-model="item.date_of_birth"
                                    :class="{ 'is-danger': $v.item.date_of_birth.$error }"
                                    @input="$v.item.date_of_birth.$touch()"
                                ></input-mask>
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Email</label>
                            </div>
                            <div class="control">
                                <input
                                    class="input"
                                    type="email"
                                    v-model="item.email"
                                    :class="{ 'is-danger': $v.item.email.$error }"
                                    @input="$v.item.email.$touch()"
                                >
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Телефон</label>
                            </div>
                            <div class="control">
                                <input-mask
                                    mask="+7 (999) 999 99 99"
                                    class="input"
                                    type="text"
                                    v-model="item.phone"
                                    :class="{ 'is-danger': $v.item.phone.$error }"
                                    @input="$v.item.phone.$touch()"
                                ></input-mask>
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">AGENT_MEDECINS_ID *</label>
                            </div>
                            <div class="control">
                                <input
                                    class="input"
                                    type="text"
                                    v-model="item.medecins_id"
                                    :class="{ 'is-danger': $v.item.medecins_id.$error }"
                                    @input="$v.item.medecins_id.$touch()"
                                >
                            </div>
                        </div>

                        <fieldset class="box">
                            <legend class="label has-text-centered">Паспорт</legend>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">Серия, номер</label>
                                </div>
                                <div class="control">
                                    <input-mask
                                        mask="9999 999999"
                                        class="input"
                                        type="text"
                                        v-model="item.passport_no"
                                        :class="{ 'is-danger': $v.item.passport_no.$error }"
                                        @input="$v.item.passport_no.$touch()"
                                    ></input-mask>
                                </div>
                            </div>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">Дата выдачи</label>
                                </div>
                                <div class="control">
                                    <input-mask
                                        mask="99.99.9999"
                                        class="input"
                                        type="text"
                                        v-model="item.passport_date_of_issue"
                                        :class="{ 'is-danger': $v.item.passport_date_of_issue.$error }"
                                        @input="$v.item.passport_date_of_issue.$touch()"
                                    ></input-mask>
                                </div>
                            </div>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">Место выдачи</label>
                                </div>
                                <div class="control">
                                    <input
                                        class="input"
                                        type="text"
                                        v-model="item.passport_place_of_issue"
                                        :class="{ 'is-danger': $v.item.passport_place_of_issue.$error }"
                                        @input="$v.item.passport_place_of_issue.$touch()"
                                    >
                                </div>
                            </div>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">Адрес регистрации</label>
                                </div>
                                <div class="control">
                                    <input
                                        class="input"
                                        type="text"
                                        v-model="item.passport_address_of_registration"
                                        :class="{ 'is-danger': $v.item.passport_address_of_registration.$error }"
                                        @input="$v.item.passport_address_of_registration.$touch()"
                                    >
                                </div>
                            </div>
                        </fieldset>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">СНИЛС</label>
                            </div>
                            <div class="control">
                                <input
                                    class="input"
                                    type="text"
                                    v-model="item.snils"
                                    :class="{ 'is-danger': $v.item.snils.$error }"
                                    @input="$v.item.snils.$touch()"
                                >
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Расчетный счет</label>
                            </div>
                            <div class="control">
                                <input
                                    class="input"
                                    type="text"
                                    v-model="item.checking_account"
                                    :class="{ 'is-danger': $v.item.checking_account.$error }"
                                    @input="$v.item.checking_account.$touch()"
                                >
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">БИК</label>
                            </div>
                            <div class="control">
                                <input
                                    class="input"
                                    type="text"
                                    v-model="item.bic"
                                    :class="{ 'is-danger': $v.item.bic.$error }"
                                    @input="$v.item.bic.$touch()"
                                >
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Корреспондентский счет</label>
                            </div>
                            <div class="control">
                                <input
                                    class="input"
                                    type="text"
                                    v-model="item.correspondent_account"
                                    :class="{ 'is-danger': $v.item.correspondent_account.$error }"
                                    @input="$v.item.correspondent_account.$touch()"
                                >
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label"></label>
                            </div>

                            <div class="control is-grouped">
                                <p class="control">
                                    <button class="button is-primary"
                                        :class="{ 'is-loading': saving, 'is-disabled': $v.$invalid }"
                                    >
                                        Сохранить
                                    </button>
                                </p>
                                <p class="control">
                                    <router-link :to="{ name: 'Agents'}" class="button is-link" exact>Отменить</router-link>
                                </p>
                            </div>

                        </div>

                    </form>
                </article>

            </div>
        </div>
    </div>
</template>

<script>
import { required, email, numeric } from 'vuelidate/lib/validators'
import { regex, datetime } from '../../utils/validators'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import agents from '../../services/agents'
import ui from '../../utils/ui'

export default {
  mixins: [createOrUpdateViewMixin(agents, {})],
  computed: {
    referUrl () {
      return (!this.item && !this.item.uuid) ? null : 'https://chaika.com/appointment?agent=' + this.item.uuid
    }
  },
  methods: {
    onCopyReferUrl () {
      // ui.openNotification({
      //   message: 'Скопировано',
      //   type: 'success'
      // })
    }
  },
  validations: {
    item: {
      medecins_id: {
        required,
        numeric
      },
      full_name: {
      },
      date_of_birth: {
        datetime: datetime('DD.MM.YYYY')
      },
      email: {
        email
      },
      phone: {
        regex: regex(/\+\d \(\d{3}\) \d{3} \d{2} \d{2}/)
      },
      snils: {
      },
      passport_no: {
        regex: regex(/\d{4} \d{6}/)
      },
      passport_date_of_issue: {
        datetime: datetime('DD.MM.YYYY')
      },
      passport_place_of_issue: {
      },
      passport_address_of_registration: {
      },
      checking_account: {
      },
      bic: {
      }, // Bank Identification Code
      correspondent_account: {
      }
    }
  }
}
</script>
